import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import urls from '../../../config/urls';

function MemberList({ firstName, lastName, type, salutation, onClose }) {
  const navigate = useNavigate();

  const logout = () => {
    navigate(urls.logout);
  }

  const donate = () => {
    window.location = urls.donateFundraiseupStandard;
  }

  const joinNow = () => {
    navigate(urls.memberRegistration);
  }
  
  const renderMenu = () => {
    return (
      <div 
        className="MemberList"
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}>
        
        <div className="MemberList-user">
          <Button variant="contained" color="secondary" onClick={logout}>Log out</Button>
          { type === 'member' && 
            <p className="name">{`${firstName || ''} ${lastName || ''}`}</p> }
          { type === 'magdala-member' &&
            <p className="name">{`${salutation || ''} ${firstName || ''}`}</p>}
          { !type &&
            <p className="name">{`${firstName || ''} ${lastName || ''}`}</p>
          }
        </div>

        <Divider />

        <div className="MemberList-donate">
          <Button color="primary" onClick={donate}>Donate</Button>
        </div>

        <Divider />

        <div className="MemberList-profile">
          <h3>Profile</h3>
          <ul>
            <li className="menu-item personal-info"><Link to={urls.profile}>Personal Information</Link></li>
            <li className="menu-item student-record"><Link to={urls.myTranscript}>Student Transcript</Link></li>
            <li className="menu-item donations"><Link to={urls.myDonations}>My Donations</Link></li>
          </ul>
        </div>

        <Divider />

        <div className="MemberList-actions">
          <h3>Actions</h3>
          <ul>
            <li className="menu-item change-password">
              <Link to={urls.profileChangePassword}>Change Password</Link>
            </li>
            <li className="menu-item email-preferences">
              <Link to={urls.profileEmailNotifications}>Manage Email Preferences</Link>
            </li>
          </ul>
        </div>

      </div>
    );
  }

  const renderTrial = () => {
    return (
      <div 
        className="MemberList trial"
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}>
        <div className="MemberList-user">
          <Button variant="contained" color="secondary" onClick={logout}>Log out</Button>
          <p className="notification">
            You are using our trial account access. No member profile features are currently enabled.
          </p>

          <Divider />

          <div className="join-prompt">
            <h3>Ready to sign-up for access to our full library?</h3>
            <p>Joining is quick, easy, and free.</p>
            <Button variant="outlined" color="primary" onClick={joinNow}>Get Started</Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      { type === 'trial'?
        <Fragment>{renderTrial()}</Fragment> :
        <Fragment>{renderMenu()}</Fragment>
      }
    </Fragment>
  );
}

MemberList.propTypes = {
  onClose: PropTypes.func
};

function mapStateToProps(state) {
  const { firstName, lastName, type, salutation } = state.user;
    return { firstName, lastName, type, salutation }; 
}

export default connect(mapStateToProps)(MemberList);